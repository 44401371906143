.App{
  position: absolute;
  background-image: url(https://i.pinimg.com/originals/06/6c/7a/066c7acda8e46d8b7e3637c01c7197d4.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100vw;
  height: 100vh;
  z-index: -10;
  background-size: cover;
  overflow: hidden;
}

.viewport {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 0;
}


.Servant{
  position: relative;
  width: 1639px;
  height: 1229px;
  display: flex;
  align-self: flex-end;
}

.Body{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 1;
}

.Face {
  position: absolute;
  height: 1229px;
  width: 1639px;
  background-size: 410px 410px;
  background-repeat: no-repeat;
  background-position: 626px 240px;
  z-index: 2;
}

.TextBox{
  background-image: url(./images/dialog_box.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  background-size: 100% 100%;
  width: 1312px;
  height: 335px;
  margin-bottom: 2%;
  z-index: 3;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.BoxContent{
  margin-left: 3%;
  margin-right: 5%;
}

.Name{
  color: white;
  font-size: 3.7em;
}

.Dialogue{
  color: white;
  margin-top: 99%;
  font-size: 3.5em;
}